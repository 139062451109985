import styled from '@emotion/styled';

export const ContentWrapper = styled('div') <{ layout: 'col' | 'grid' }>`
  display: grid;
  grid-template-columns: ${({ layout }) =>
        layout === 'grid' ? 'repeat(auto-fit, minmax(220px, 330px))' : '1fr'};
  column-gap: 40px;
  gap: 40px;
  justify-content: flex-start;
  align-items: center;
  padding-right: 20px;
  padding-bottom: 40px;
  position: relative;

  @media (max-width: 1250px) {
    justify-content: center;
  }

  @media (max-width: 948px) {
    padding-left: 20px;
  }
`;
