import axios from 'axios';

export const BASE_API_PATH =
    process.env.REMOTE_HOST ?? 'https://playai-backend.aoneiro.com/';

export const baseAPIInstance = axios.create({
    baseURL: BASE_API_PATH,
});

export const TOKEN_LS_PATH = 'plai-access';

baseAPIInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem(TOKEN_LS_PATH);

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
});
