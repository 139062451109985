import styled from '@emotion/styled';
import { Flex } from 'antd';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  border-radius: 16px;
  margin-top: 28px;
  background-color: ${({ theme }) => theme.COLORS.BLACK._400};
  column-gap: 20px;

  @media (max-width: 948px) {
    flex-direction: column;
    row-gap: 12px;
  }
`;

export const ImproveButton = styled('button')`
  background-color: ${({ theme }) => theme.COLORS.ACCENT._200};
  border: 1px solid ${({ theme }) => theme.COLORS.ACCENT._200};
  cursor: pointer;
  border-radius: 30px;
  padding: 8px 16px;
  color: ${({ theme }) => theme.COLORS.BLACK._400};
`;

export const Title = styled.p`
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: ${({ theme }) => theme.COLORS.WHITE._200};
  margin: 0;
`;

export const Description = styled.span`
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.2px;
  margin: 0;
  display: inline;
  text-align: center;
  color: ${({ theme }) => theme.COLORS.WHITE._600};
`;

export const Label = styled.p`
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  margin: 0;
  display: inline;
  color: ${({ theme }) => theme.COLORS.ACCENT._200};
`;

export const FlexStyled = styled(Flex)`
  gap: 20px;

  @media (max-width: 948px) {
    flex-direction: column;
    gap: 4px;
  }
`;
