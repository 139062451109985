import { useEffect, useMemo, useState } from 'react';

const TABLET_BREAKPOINT = 450 - 15;

const getWidth = () =>
    document.documentElement.clientWidth ||
    window.innerWidth ||
    document.body.clientWidth;

export const useWidth = () => {
    const [width, setWidth] = useState(getWidth());

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;

        const handleResize = () => {
            if (timeoutId) clearTimeout(timeoutId);

            setWidth(getWidth());

            timeoutId = setTimeout(() => setWidth(getWidth()), 150);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const isTablet = useMemo(() => width <= TABLET_BREAKPOINT, [width]);
    const isDesktop = useMemo(() => width > TABLET_BREAKPOINT, [width]);

    return { width, isTablet, isDesktop };
};
