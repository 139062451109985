import styled from '@emotion/styled';
import { Form, Input, Modal, Tooltip } from 'antd';
import App from 'antd/lib/app';
import { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { API } from '../../api';
import StatIcon from '../../components/icons/stat-icon';
import { TooltipQuestionIcon } from '../../components/icons/tooltip-question';
import { LocaleKeys } from '../../locale';
import { useAppDispatch } from '../../state';
import { useUserAccountSelector } from '../../state/auth/selectors';
import {
  TFirstStage,
  createVideoNextStageAction,
  createVideoSetFirstStageAction,
} from '../../state/video/createSlice';
import { TUploadByLinkResponse } from '../../types/responses/video';
import { URL_REGEX } from '../../utils/regex';

type TProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  withSaving: boolean;
};

export type TLinkForm = {
  url: string;
};

const IconWrapper = styled('div')`
  width: 18px;
  margin-top: 8px;

  svg {
    width: 18px;
    height: 18px;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const UploadWithLinkModal: FC<TProps> = ({
  open,
  setOpen,
  withSaving,
}) => {
  const { t } = useTranslation([LocaleKeys.MODAL]);
  const dispatch = useAppDispatch();
  const account = useUserAccountSelector();
  const { message } = App.useApp();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const onClose = useCallback(() => {
    setOpen(false);
    form.resetFields();
  }, []);

  const handleSaveStage = useCallback(
    (
      data: Partial<TFirstStage> & {
        size?: number;
      }
    ) => {
      dispatch(createVideoSetFirstStageAction(data));
    },
    []
  );

  const onFormFinish = async (values: TLinkForm) => {
    if (!account) {
      message.warning(t('rules.warning'));
      return;
    }

    try {
      setLoading(true);

      let videoItem: TUploadByLinkResponse | string;

      if (withSaving) {
        videoItem = (await API.videos.uploadWithUrl(values.url)).data;
      } else {
        videoItem = (await API.videos.addWithoutSave(values.url)).data;
      }

      handleSaveStage({
        videoDuration: videoItem.lengthSeconds,
        uploadResponse: {
          originUrls: videoItem.Location,
          s3Keys: videoItem.Key,
          size: videoItem.size,
        },
        uploaded: true,
        pending: false,
        filled: true,
        size: videoItem.size,
        uploadByLinkWithoutSaveVideoId:
          typeof videoItem === 'string' ? videoItem : '',
        uploadByLinkWithoutSaveVideoLink:
          typeof videoItem === 'string' ? values.url : '',
      });

      dispatch(createVideoNextStageAction());

      message.success(t('withLinkUpload.success'));
      setLoading(false);
      onClose();
    } catch (e) {
      message.error(t('withLinkUpload.error'));
      setLoading(false);
    }
  };

  return (
    <Modal
      title={
        <Title>
          {withSaving
            ? t('withLinkUpload.title')
            : t('withLinkUpload.titleWithoutSaving')}

          <Tooltip
            title={t('withLinkUpload.warningUploadVideo')}
            placement="bottom"
          >
            <IconWrapper>
              <TooltipQuestionIcon />
            </IconWrapper>
          </Tooltip>
        </Title>
      }
      onCancel={onClose}
      open={open}
      maskClosable={false}
      okText={t('buttons.confirm')}
      cancelText={t('buttons.cancel')}
      onOk={form.submit}
      okButtonProps={{
        loading,
      }}
    >
      <Form layout="vertical" form={form} onFinish={onFormFinish}>
        <Form.Item
          name="url"
          label={t('withLinkUpload.urlLabel')}
          rules={[
            {
              required: true,
              message: t('rules.required'),
            },
            {
              pattern: URL_REGEX,
              message: t('rules.urlInvalid'),
            },
            {
              validator: (_, value) => {
                if (
                  value &&
                  (value.includes('youtube.com') ||
                    value.includes('youtu.be') ||
                    value.includes('rutube.ru'))
                ) {
                  return Promise.reject(
                    new Error(t('rules.noYouTubeOrRuTube'))
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            className="themed"
            placeholder={t('withLinkUpload.urlPlaceholder')}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
