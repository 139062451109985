export const useGetVideoDuration = (videoFile: File): Promise<number> => {
    return new Promise((resolve, reject) => {
        const video = document.createElement('video');

        // Создаём объект URL для файла видео
        const videoUrl = URL.createObjectURL(videoFile);
        video.src = videoUrl;

        video.onloadedmetadata = () => {
            // Получаем длительность видео после загрузки метаданных
            resolve(video.duration); // duration в секундах
            URL.revokeObjectURL(videoUrl); // Освобождаем ресурсы
        };

        video.onerror = (error) => {
            reject(new Error('Failed to load video metadata'));
            URL.revokeObjectURL(videoUrl);
        };
    });
};
