import { baseAPIInstance } from '../instanse';

export const initMultipartUpload = (
    fileName: string,
    fileType: string,
    fileSize: number
) =>
    baseAPIInstance.post(`/video/init-multipart-upload`, {
        fileName,
        fileType,
        fileSize,
    });
