import { Reducer, combineReducers } from '@reduxjs/toolkit';
import { PersistConfig, persistReducer } from 'redux-persist';

import { getPersistConfig } from '../types/state';
import { analyticsReducer } from './analytics';
import { TAuthState, authReducer, logoutAction } from './auth/slice';
import { financeReducer } from './finance/inedx';
import { IAppLocaleState, appLocaleReducer } from './locale/localeSlice';
import { appLangMigrations } from './locale/migrations';
import { meReducer } from './me/slice';
import { playlistsReducer } from './playlists';
import { uploadProgressReducerMain } from './upload-progress';
import { usersReducer } from './users';
import { videoReducers } from './video';

const userAuthConfig: PersistConfig<TAuthState> = getPersistConfig({
  key: 'user/auth',
});

const localConfig: PersistConfig<IAppLocaleState> = getPersistConfig({
  key: 'app/locale',
  stateMigrations: appLangMigrations,
});

const appReducer = combineReducers({
  auth: persistReducer(userAuthConfig, authReducer),
  me: meReducer,
  video: videoReducers,
  playLists: playlistsReducer,
  analytics: analyticsReducer,
  users: usersReducer,
  locale: persistReducer(localConfig, appLocaleReducer),
  finance: financeReducer,
  uploadProgress: uploadProgressReducerMain,
});

export type RootCombine = ReturnType<typeof appReducer>;

export const rootReducer: Reducer = (state: RootCombine, action) => {
  if (action.type === logoutAction.type) {
    localStorage.clear();
  }

  return appReducer(state, action);
};
