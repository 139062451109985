const KidsModeSettings = {
    viewingGuarantee: true,
    increaseAttention: true,
    increaseAttentionValue: 50,
    minimumAttentionLimit: true,
    minimumAttentionLimitValue: 50,
};

const HealthSafetyModeSettings = {
    viewingGuarantee: true,
    increaseAttention: true,
    increaseAttentionValue: 90,
    minimumAttentionLimit: true,
    minimumAttentionLimitValue: 90,
};

const TrainigModeSettings = {
    viewingGuarantee: true,
    increaseAttention: true,
    increaseAttentionValue: 60,
    minimumAttentionLimit: true,
    minimumAttentionLimitValue: 60,
};

const AdvertisingMarketingModeSettings = {
    viewingGuarantee: true,
    increaseAttention: true,
    increaseAttentionValue: 70,
    minimumAttentionLimit: true,
    minimumAttentionLimitValue: 70,
};

export const ModeSettings = {
    kidsMode: KidsModeSettings,
    healthSafety: HealthSafetyModeSettings,
    training: TrainigModeSettings,
    advertisingMarketing: AdvertisingMarketingModeSettings,
    customSettings: {},
};
