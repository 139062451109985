import { useUploadProgress } from '../../state/upload-progress/selectors';
import {
    containerStyle,
    progressBarContainerStyle,
    progressBarStyle,
    slideDownAnimation,
    textStyle,
} from './upload-progress.styled';

export const UploadProgressBar = () => {
    const { progress, visible } = useUploadProgress();

    return (
        <div
            css={[containerStyle, visible && slideDownAnimation]} // Применяем стили с анимацией
        >
            <div css={textStyle}>{`Uploading: ${progress}%`}</div>
            <div css={progressBarContainerStyle}>
                <div css={[progressBarStyle, { width: `${progress}%` }]} />
            </div>
        </div>
    );
};
