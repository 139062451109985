import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  max-width: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1e4141;
  border-radius: 20px;
  padding-left: 12px;
  gap: 20px;
  height: 33px;

  @media (max-width: 948px) {
    max-width: none;
  }
`;

export const DesignButton = styled('button')`
  background-color: ${({ theme }) => theme.COLORS.ACCENT._200};
  border: 1px solid ${({ theme }) => theme.COLORS.ACCENT._200};
  cursor: pointer;
  border-radius: 30px;
  padding: 8px 16px;
  color: ${({ theme }) => theme.COLORS.BLACK._400};
`;

export const Title = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  color: ${({ theme }) => theme.COLORS.WHITE._200};
  margin: 0;
  align-self: center;
`;
