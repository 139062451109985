import styled from '@emotion/styled';
import { Flex } from 'antd';

export const ContentWrapper = styled('div') <{ layout: 'col' | 'grid' }>`
  display: grid;
  grid-template-columns: ${({ layout }) =>
        layout === 'grid' ? 'repeat(auto-fit, minmax(220px, 330px))' : '1fr'};
  column-gap: 40px;
  gap: 40px;
  justify-content: flex-start;
  align-items: center;
  padding-right: 20px;
  padding-bottom: 40px;
  position: relative;

  @media (max-width: 1250px) {
    justify-content: center;
  }

  @media (max-width: 948px) {
    padding-left: 20px;
  }
`;

export const TableNotificationsContainer = styled.div`
  width: 100%;
  padding: 0px 16px;
`;

export const FlexTitle = styled(Flex)`
  gap: 20px;
  align-items: center;
`;

export const Title = styled.h1`
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  color: ${({ theme }) => theme.COLORS.WHITE._100};
  white-space: nowrap;
`;

export const LoadingWrapper = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 54px;
`;

export const AddVideoButton = styled('button')`
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 16px;
  color: ${({ theme }) => theme.COLORS.WHITE._100};
  background-color: transparent;
  border: none;
  margin-right: 16px;
  font-weight: 500;
  transition: color 0.3s ease-out;
  padding: 8px;

  svg .primary,
  svg .secondary {
    transition: fill 0.3s ease-out;
  }

  &:hover {
    svg .primary {
      fill: ${({ theme }) => theme.COLORS.ACCENT._200};
    }

    svg .secondary {
      fill: ${({ theme }) => theme.COLORS.BLACK._100};
    }

    color: ${({ theme }) => theme.COLORS.ACCENT._200};
  }
`;
