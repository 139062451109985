import { useAppSelector } from '../index';
import { RootCombine } from '../root';

const useAnalyticsSelector = (state: RootCombine) => state.analytics;

export const useAnalyticsFilterSelector = () =>
  useAppSelector((state) => useAnalyticsSelector(state).filters);
export const useAnalyticsDataSelector = () =>
  useAppSelector((state) => useAnalyticsSelector(state).all);
export const useDynamicsDataSelector = () =>
  useAppSelector((state) => useAnalyticsSelector(state).dynamics);

export const isHaveLimitOnAnalyticsViewSelector = () =>
  useAppSelector(
    (state) => useAnalyticsSelector(state).all.data?.is_hidden_users_stats_main
  );
