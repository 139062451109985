import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  TUploadVideoResponse,
  TVideoCreateForm,
} from '../../types/responses/video';

export type TSelectedStage = 'first' | 'second';

type TStage<T> = {
  filled: boolean;
  pending: boolean;
  uploaded: boolean;
  uploadResponse?: T;
};

export type TFirstStage = TStage<TUploadVideoResponse> & {
  file?: File;
  videoDuration?: number;
  uploadByLinkWithoutSaveVideoId?: string;
  uploadByLinkWithoutSaveVideoLink?: string;
};

export type TSecondStage = TStage<unknown> & {
  form: Omit<
    TVideoCreateForm,
    'videoPreviewId' | 'originUrls' | 's3Keys' | 'playerMode'
  >;
};

type TState = {
  currentStage: TSelectedStage;
  firstStage: TFirstStage;
  secondStage: TSecondStage;
};

export const initialSecondStageFormData: TSecondStage['form'] = {
  title: '',
  description: undefined,
  duration: 0,
  size: 0,
};

const initialState: TState = {
  currentStage: 'first',
  firstStage: {
    filled: false,
    pending: false,
    uploaded: false,
    uploadResponse: undefined,
    videoDuration: undefined,
  },
  secondStage: {
    filled: false,
    pending: false,
    uploaded: false,
    uploadResponse: undefined,
    form: {
      ...initialSecondStageFormData,
    },
  },
};

const createVideoSlice = createSlice({
  name: 'video/create',
  initialState: { ...initialState } as TState,
  reducers: {
    createVideoSetFirstStageAction: (
      state,
      action: PayloadAction<
        Partial<TFirstStage> & {
          size?: number;
        }
      >
    ) => {
      state.firstStage = {
        ...state.firstStage,
        ...action.payload,
      };

      if (action.payload.size) {
        state.secondStage = {
          ...state.secondStage,
          form: {
            ...state.secondStage.form,
            size: action.payload.size,
          },
        };
      }
    },
    createVideoSetSecondStageAction: (
      state,
      action: PayloadAction<Partial<TSecondStage>>
    ) => {
      state.secondStage = {
        ...state.secondStage,
        ...action.payload,
      };
    },
    createVideoSetSecondFormAction: (
      state,
      action: PayloadAction<Partial<TSecondStage['form']>>
    ) => {
      state.secondStage = {
        ...state.secondStage,
        form: {
          ...state.secondStage.form,
          ...action.payload,
        },
      };
    },
    createVideoNextStageAction: (state) => {
      const currentStage = state.currentStage;

      if (currentStage === 'first') {
        state.currentStage = 'second';
      }
    },
    createVideoResetStateAction: (state) => {
      state.currentStage = 'first';
      state.firstStage = {
        ...initialState.firstStage,
      };
      state.secondStage = {
        ...initialState.secondStage,
      };
    },
  },
});

export const {
  createVideoSetFirstStageAction,
  createVideoNextStageAction,
  createVideoResetStateAction,
  createVideoSetSecondStageAction,
  createVideoSetSecondFormAction,
} = createVideoSlice.actions;
export const createVideoReducer = createVideoSlice.reducer;
