import { css, keyframes } from '@emotion/react';

// Анимация для плавного выезда сверху
export const slideDown = keyframes`
    0% {
        top: -60px; 
    }
    100% {
        top: 20px;  
    }
`;

export const slideDownAnimation = css`
  animation: ${slideDown} 0.5s ease-in-out forwards;
`;

export const containerStyle = css`
  min-width: 180px;
  position: fixed;
  top: -60px; /* Изначально скрыт за экраном */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7); /* Темный фон */
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  z-index: 9999;
  transition: top 0.5s ease-in-out; /* Плавная анимация для подъема */
`;

export const textStyle = css`
  font-size: 12px;
  margin-bottom: 4px;
  text-align: center;
`;

export const progressBarContainerStyle = css`
  width: 100%;
  height: 6px;
  background-color: #e0e0e0;
  border-radius: 5px;
`;

export const progressBarStyle = css`
  height: 100%;
  background-color: #4caf50;
  border-radius: 5px;
`;
