import { Flex } from 'antd';
import Link from 'antd/lib/typography/Link';
import { useTranslation } from 'react-i18next';

import { LocaleKeys } from '../../../locale';
import { useMeTariffPlan } from '../../../state/me/selectors';
import {
    Container,
    Description,
    FlexStyled,
    ImproveButton,
    Label,
    Title,
} from './LimitAnalyticsViewNotification.styled';
import { WarningIcon } from './WarningIcon';

export const LimitAnalyticsViewNotification = () => {
    const { t } = useTranslation([LocaleKeys.ANALYTICS]);

    const myPlan = useMeTariffPlan();

    return (
        <Container>
            <FlexStyled align="center">
                <Flex gap={4} align="center">
                    <WarningIcon />
                    <Title>{t('notifications.limitHasBeenReached')}</Title>
                </Flex>

                <Description>
                    {t('notifications.limitStart')}{' '}
                    <Label>{myPlan?.usersCountViewingStats}</Label>{' '}
                    {t('notifications.limitEnd')}
                </Description>
            </FlexStyled>
            <Link href="/finance">
                <ImproveButton>{t('notifications.improveTariff')}</ImproveButton>
            </Link>
        </Container>
    );
};
