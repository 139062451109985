import Icon from '@ant-design/icons';

const WarningSVG = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="30" height="30" rx="15" fill="#1E4141" />
        <path
            d="M16.503 6L16.2885 18.7054H14.0034L13.7889 6H16.503ZM15.1501 24C14.6936 24 14.3031 23.8316 13.9786 23.4949C13.6541 23.1524 13.4946 22.7402 13.5001 22.2583C13.4946 21.7823 13.6541 21.3759 13.9786 21.0392C14.3031 20.6967 14.6936 20.5254 15.1501 20.5254C15.5955 20.5254 15.9805 20.6967 16.305 21.0392C16.6295 21.3759 16.7945 21.7823 16.8 22.2583C16.7945 22.5776 16.7148 22.8708 16.5608 23.1379C16.4123 23.3991 16.2143 23.6081 15.9668 23.7649C15.7193 23.9216 15.4471 24 15.1501 24Z"
            fill="#39D5C9"
        />
    </svg>
);

export const WarningIcon = () => <Icon component={WarningSVG} />;
