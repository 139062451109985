import styled from '@emotion/styled';

const Wrapper = styled('div')`
  padding: 20px;
  p {
    padding: 0;
    margin: 0;
    text-indent: 20px;
  }
  // styles for multi-level numbered list
  ol {
    list-style: none;
    counter-reset: li;
  }
  li:before {
    counter-increment: li;
    content: counters(li, '.') '. ';
  }
`;

const Header = styled('h1')`
  width: 100%;
  text-align: center;
  font-size: 18px;
`;

export const PublicOfferPage = () => {
    return (
        <Wrapper>
            <Header>
                ОФЕРТА <br />
                на заключение лицензионного соглашения <br /> на предоставление права
                использования программ для ЭВМ
            </Header>
            <p style={{ textIndent: 0 }}>Российская Федерация, город Москва.</p>
            <p style={{ textIndent: 0 }}>Дата размещения: 27 сентября 2024 г.</p>
            <p style={{ textIndent: 0 }}>
                Дата вступления в силу: 27 сентября 2024 г.
            </p>
            <br />
            <p>
                Настоящий документ является официальным предложением – офертой
                Акционерного общество «Нейросети» (далее – «Лицензиар») на заключение
                лицензионного соглашения на предоставление права использования программ
                для ЭВМ, правообладателем или надлежащим лицензиатом которых является
                Лицензиар, адресованное неопределенному кругу лиц (далее – «Лицензиат»),
                на изложенных ниже условиях и публикуется дистанционным образом в сети
                интернет на условиях, определенных в настоящем Соглашении и содержит все
                существенные условия Оферты.
            </p>
            <p>
                В соответствии с пунктом 2 статьи 437 Гражданского Кодекса Российской
                Федерации данный документ является публичной офертой.
            </p>
            <p>
                В соответствии со ст. 436 Гражданского кодекса РФ настоящая Оферта не
                является безотзывной.
            </p>
            <p>
                Настоящая публичная оферта (Соглашение) заключается в особом порядке:
                путем акцепта настоящего Соглашения, содержащего все существенные
                условия Соглашения, без подписания его сторонами. Настоящий Соглашение
                имеет юридическую силу в соответствии со ст.434 Гражданского кодекса
                Российской Федерации и является равносильным Соглашению, подписанному
                Сторонами.
            </p>
            <ol>
                <br />
                <li>Термины и определения</li>
                <br />
                <ol>
                    <p style={{ textIndent: 0 }}>
                        В целях настоящего Соглашения нижеприведенные термины используются в
                        следующем значении:
                    </p>
                    <br />
                    <li>
                        Соглашение — возмездное лицензионное Соглашение между Лицензиатом и
                        Лицензиаром на предоставление права использования Программного
                        обеспечения, который заключается посредством Акцепта Оферты
                        Лицензиатом;
                    </li>
                    <li>
                        Сайт — интернет-страница Лицензиара в сети Интернет, расположенная
                        по адресу:{' '}
                        <a target="_blank" href="https://neuroplai.ru">
                            https://neuroplai.ru
                        </a>{' '}
                        на которой опубликована Оферта.
                    </li>
                    <li>
                        Акцепт Оферты — полное и безоговорочное принятие Оферты путем
                        осуществления действий, указанных в разделе 6 Оферты;
                    </li>
                    <li>
                        Лицензиат — лицо, осуществившее Акцепт Оферты, заключившее таким
                        образом Соглашение с Лицензиаром;
                    </li>
                    <li>
                        Программное обеспечение (ПО) — программа для ЭВМ «NeiroPlai». Полное
                        описание функциональных характеристик ПО содержится на сайте :{' '}
                        <a target="_blank" href="https://neuroplai.ru">
                            https://neuroplai.ru
                        </a>{' '}
                        ;
                    </li>
                    <li>
                        Срок действия лицензии — срок, указанный в Счете, в течение которого
                        Лицензиат вправе использовать Программное обеспечение;
                    </li>
                    <li>Сторона — Лицензиар или Лицензиат;</li>
                    <li>Стороны — совместно Лицензиар и Лицензиат;</li>
                    <li>Территория — территория всех стран мира;</li>
                    <li>
                        Передача Лицензии — представление лицензии на Программное
                        обеспечение, предназначенный для использования одним Лицензиатом.{' '}
                    </li>
                    <li>
                        Лицензия или Лицензия на ПО — право использования Программного
                        обеспечение Лицензиатом.
                    </li>
                    <li>
                        Личный кабинет Лицензиата — персональный закрытый раздел Лицензиата
                        на Сайте, представляющий собой совокупность защищенных паролем
                        страниц на Cайте, создаваемых при активации Личного Кабинета и
                        содержащих данные Лицензиата, в котором Лицензиату после его
                        Авторизации на Сайте доступны лицензионные права использования ПО.
                    </li>
                    <li>
                        Авторизация — действия Лицензиата на Сайте, направленные на
                        осуществление доступа к интерфейсу Сайта и предоставление
                        возможности использования сервисами Сайта посредством ввода своих
                        аутентификационных данных (наименование юридического лица,
                        реквизиты, фамилию, имя, отчество представителя юридического лица,
                        адрес электронной почты, номер телефона).
                    </li>
                    <p style={{ textIndent: 0 }}>
                        В Оферте могут быть использованы термины, не определенные в
                        настоящем разделе 1 Оферты, в этом случае толкование такого термина
                        производится в соответствии с текстом Оферты. В случае отсутствия
                        однозначного толкования термина в тексте Оферты следует
                        руководствоваться толкованием термина, определенным: в первую
                        очередь — законодательством Российской Федерации, затем —
                        сложившимся (общеупотребимым) в сети Интернет.
                    </p>
                </ol>
                <br />
                <li>Предмет Соглашения</li>
                <br />
                <ol>
                    <li>
                        Предметом Соглашения является предоставление Лицензиату на
                        возмездной основе права использования Программного обеспечения на
                        условиях простой (неисключительной) лицензии способами, указанными в
                        настоящем Соглашении.
                    </li>
                    <li>
                        Право использования Программного обеспечения в соответствии
                        предоставляется на Территории на Срок действия лицензии, указанный в
                        Счете.
                    </li>
                    <li>
                        В соответствии с условиями Соглашения Лицензиату предоставляется
                        право использования Программного обеспечения, в том числе, запуск,
                        отображение, осуществление доступа к ПО согласно его функциональному
                        назначению.
                    </li>
                </ol>
                <br />
                <li>
                    Условия предоставления права использования Программного обеспечения
                </li>
                <br />
                <ol>
                    <li>
                        Обязательным условием предоставления права использования
                        Программного обеспечения является авторизация Лицензиата в личном
                        кабинете на Сайте Лицензиара.
                    </li>
                    <li>
                        Обязательным условием предоставления права использования
                        Программного обеспечения является соблюдение Лицензиатом требований,
                        изложенных в Оферте, а также определенных в следующих документах:
                    </li>
                    <ol>
                        <li>
                            Пользовательское соглашение, размещенное по адресу:{' '}
                            <a target="_blank" href="https://neuroplai.ru">
                                https://neuroplai.ru
                            </a>
                            ;
                        </li>
                        <li>
                            Политика в отношении обработки персональных данных, размещенная по
                            адресу:{' '}
                            <a target="_blank" href="https://neuroplai.ru">
                                https://neuroplai.ru
                            </a>
                            ;
                        </li>
                    </ol>
                    <li>
                        Передача Лицензии — предоставление Лицензиаром право использования
                        Программного обеспечения на условиях простой (неисключительной)
                        лицензии и возможности использования Программного обеспечения в
                        предусмотренных Соглашением пределах путем представления кода с
                        доступом после осуществления Лицензиатом Акцепта Оферты. Передача
                        Лицензий осуществляется в течение 10 (десяти) рабочих дней с момента
                        Акцепта Оферты Лицензиатом; Передача Лицензий осуществляется путем
                        передачи Лицензиаром Лицензиату кода доступа на электронную почту,
                        указанную при регистрации в личном кабинете и активации кода
                        Лицензиатом в личном кабинете Лицензиата на Сайте или в виде
                        добавления Лицензиаром в личный кабинет Лицензиата на Сайте прав
                        доступа и изменения статуса Лицензиата касательно прав пользователя
                        ПО.
                    </li>
                    <li>
                        Дата начала срока действия лицензии является дата активации прав
                        доступа и ввода кода Лицензиата в личном кабинете на Сайте или дата
                        добавления Лицензиаром в личный кабинет Лицензиата на Сайте прав
                        доступа и изменения статуса Лицензиата касательно прав пользователя
                        ПО.
                    </li>
                    <li>
                        Лицензиат самостоятельно несет ответственность за сохранность и
                        конфиденциальность своих регистрационных данных (логина и пароля) на
                        Сайте. Все действия, совершенные с использованием полученного
                        Лицензиатом Лицензии, считаются совершенными Лицензиатом и
                        ответственность за такие действия несет Лицензиат.
                    </li>
                </ol>
                <br />
                <li>Права и обязанности Сторон</li>
                <br />
                <ol>
                    <li>Лицензиар обязуется:</li>
                    <ol>
                        <li>
                            Предоставить право использования Программного обеспечения в
                            соответствии с Соглашением, заключенным на условиях Оферты, путем
                            Передачи Лицензии;
                        </li>
                        <li>
                            Обеспечить возможность формирования Счета и ознакомления
                            Лицензиата с Офертой; при этом Лицензиар не несет ответственности
                            в случае невозможности ознакомления Лицензиата с Офертой по
                            причинам, не зависящим от Лицензиара;
                        </li>
                        <li>
                            Информировать Лицензиата о новых версиях Программного обеспечения
                            на сайте Лицензиара.
                        </li>
                        <li>
                            Обеспечивать Лицензиата необходимой технической и пользовательской
                            информацией о Программном обеспечении, а также предоставлять
                            Лицензиату по его требованию необходимые консультации.
                        </li>
                    </ol>
                    <li>Лицензиар имеет право:</li>
                    <ol>
                        <li>
                            Временно приостановить предоставление права использования
                            Программного обеспечения по техническим, технологическим или иным
                            причинам, препятствующим такому предоставлению, на время
                            устранения таких причин;
                        </li>
                        <li>
                            Приостановить предоставление права использования Программного
                            обеспечения и/или досрочно отказаться от исполнения Соглашения в
                            одностороннем внесудебном порядке путем уведомления Лицензиата в
                            случаях: а) нарушения Лицензиатом обязательств, принятых в
                            соответствии с Соглашением, б) если Лицензиат имеет задолженность
                            по оплате вознаграждения по Соглашению;
                        </li>
                        <li>
                            Вносить изменения в Оферту и иные документы, указанные в п. 3.1
                            Оферты, в порядке, установленном разделом 7 Оферты.
                        </li>
                    </ol>
                    <li>Лицензиат обязуется:</li>
                    <ol>
                        <li>
                            По требованию Лицензиара в срок, не превышающий 3 (трёх)
                            календарных дней с момента получения требования Лицензиара,
                            предоставить надлежаще заверенные копии документов, подтверждающие
                            информацию о Лицензиате;
                        </li>
                        <li>
                            Выплачивать вознаграждение в сроки и в порядке, установленные в
                            Счете;
                        </li>
                        <li>
                            Не использовать Программное обеспечение способами, прямо не
                            предусмотренными настоящей Офертой, не изменять, не
                            декомпилировать и/или не модифицировать программный код
                            Программного обеспечения каким-либо образом;
                        </li>
                        <li>
                            Не передавать Лицензии, а также регистрационные данные (логин и
                            пароль) третьим лицам; Не использовать Программное обеспечение для
                            создания интернет-сервисов, программ для ЭВМ или иным образом,
                            если такое использование влечет нарушение Законодательства,
                            документов, указанных в п. 3.1 Оферты, и/или прав и законных
                            интересов третьих лиц;
                        </li>
                        <li>
                            Не удалять, скрывать или модифицировать любые содержащиеся в
                            Программном обеспечении или в полученных с его помощью данных
                            рекламно-информационные материалы, товарные знаки, логотипы,
                            ссылки или иные указания на Лицензиара или иных лиц (при наличии),
                            равно как и любые другие уведомления и/или информацию,
                            передаваемые Программным обеспечением.
                        </li>
                        <li>Не модифицировать Лицензии;</li>
                        <li>
                            Не передавать Лицензии, а также регистрационные данные (логин и
                            пароль) третьим лицам, за исключением получения предварительного
                            письменного согласия Лицензиара в соответствии с условиями,
                            указанными в п.13.11 Оферты.
                        </li>
                    </ol>
                    <li>Лицензиат имеет право:</li>
                    <ol>
                        <li>
                            В случае несогласия с изменениями, внесенными Лицензиаром в Оферту
                            и иные документы, указанные в п. 3.1 и 3.2. Оферты, отказаться от
                            исполнения Соглашения с учетом внесенных изменений в одностороннем
                            порядке, письменно уведомив Лицензиара в срок не позднее 7 (семи)
                            календарных дней с момента вступления указанных изменений в силу.
                            В этом случае Соглашение считается расторгнутым с даты окончания
                            текущего Срока действия лицензии при условии получения Лицензиаром
                            уведомления Лицензиата. Дальнейшие взаимоотношения Сторон
                            регулируются пунктом 5.14 настоящей Оферты.
                        </li>
                        <li>
                            Лицензиат предоставляет Лицензиару право использовать логотип,
                            товарный знак, фирменное наименование и/или наименование
                            программного продукта Лицензиата и/или сайта Лицензиата в
                            информационных, рекламных и маркетинговых целях без необходимости
                            получения дополнительного согласия Лицензиата и без выплаты ему
                            какого-либо вознаграждения за такое использование.
                        </li>
                    </ol>
                </ol>
                <br />
                <li>Размер вознаграждения и порядок расчетов</li>
                <br />
                <ol>
                    <li>
                        Вознаграждение Лицензиара за предоставление права использования
                        Программного обеспечения в течение срока действия лицензии
                        рассчитывается исходя из количества Лицензий и их цены согласно
                        «Тарифного плана», размещенного на Сайте{' '}
                        <a target="_blank" href="https://neuroplai.ru">
                            https://neuroplai.ru
                        </a>
                        . Итоговый размер вознаграждения Лицензиара указывается в Счете.
                    </li>
                    <li>
                        Вознаграждение Лицензиара по Соглашению не облагается НДС на
                        основании пп.26 п.2 ст. 149 Налогового кодекса Российской Федерации.
                        Счет-фактура не выставляется.
                    </li>
                    <li>Расчеты по Соглашению производятся в российских рублях.</li>
                    <li>
                        В целях подтверждения факта передачи Лицензий Лицензиар формирует
                        односторонний акт (далее – «Акт»), и направляет Лицензиату
                        посредством сети Интернет в личный кабинет на не позднее 10 десяти
                        (десяти) рабочих дней с даты Акцепта Оферты. Копии текста Акта
                        Лицензиар имеет право направить Лицензиату по электронной.
                    </li>
                    <p style={{ textIndent: 0 }}>
                        Стороны установили, что Лицензиат считается согласившимся с данными,
                        указанными в Акте, если в течение 10 (десяти) календарных дней с
                        даты направления Акта Лицензиар не получил от Лицензиата
                        мотивированных письменных возражений. По истечении срока, указанного
                        выше, претензии относительно исполнения Соглашения, в том числе по
                        работоспособности ПО и размеру вознаграждения, не принимаются.
                    </p>
                    <li>
                        В целях Соглашения выплата вознаграждения производится в безналичном
                        порядке. Лицензиар не несет ответственности за выбранный Лицензиатом
                        способ оплаты и его последствия. Безопасность, конфиденциальность, а
                        также иные условия использования способа/формы оплаты выходят за
                        рамки Оферты и Соглашения и регулируются соглашениями (Соглашениями)
                        между Лицензиатом и соответствующими организациями.
                    </li>
                    <li>
                        Лицензиат обязуется незамедлительно уведомить Лицензиара о
                        произведенном платеже с одновременным предоставлением копии
                        платежного документа с отметкой исполняющего банка (при его наличии
                        для соответствующего способа оплаты).
                    </li>
                    <li>
                        Вознаграждение считается выплаченным Лицензиатом с момента получения
                        Лицензиаром подтверждения из банка о поступлении всей суммы оплаты
                        на расчетный счет Лицензиара. В отдельных случаях по собственному
                        усмотрению Лицензиара подтверждением факта оплаты может служить
                        факсимильная копия платежного поручения с отметкой банка об оплате
                        при безналичной форме оплаты.
                    </li>
                    <li>
                        В случаях, предусмотренных действующим законодательством, при
                        осуществлении расчетов за предоставление права использования
                        Программного обеспечения, при возврате денежных средств, полученных
                        в результате указанных расчетов, кассовый чек направляется на адрес
                        электронной почты, созданный при регистрации учетной записи, под
                        которой Лицензиат был авторизирован при оплате.
                    </li>
                    <p style={{ textIndent: 0 }}>
                        При осуществлении расчетов за предоставление права использования
                        Программного обеспечения, предусмотренных п. 5.3 ст. 1.2
                        Федерального закона от 22.05.2003 г. № 54-ФЗ, Лицензиат обязан
                        указывать в назначении платежа информацию о номере счета,
                        выставленного Лицензиаром Лицензиату, а также наименование/ФИО
                        Лицензиата. В случае неполучения Лицензиаром, отсутствия, неполноты
                        и/или недостоверности какой-либо из указанной информации расчет
                        считается совершенным только после получения Лицензиаром от
                        Лицензиата полной и достоверной информации о назначении платежа в
                        соответствии с требованиями, указанными в настоящем пункте выше.
                    </p>
                    <li>
                        Лицензиат обязуется перечислять вознаграждение в полном размере без
                        удержания каких-либо налогов, сборов и других платежей. Все налоги,
                        сборы и другие платежи, применимые в соответствии с
                        законодательством Лицензиата уплачиваются за счет Лицензиата. В
                        случае если Лицензиат в соответствии с требованиями законодательства
                        должен удерживать какие-либо налоги, пошлины, сборы и/или другие
                        платежи из вознаграждения Лицензиара как получателя дохода, то общая
                        сумма вознаграждения, подлежащая выплате Лицензиатом, признается
                        увеличенной таким образом, чтобы сумма вознаграждения Лицензиара
                        после удержания налогов составляла сумму, рассчитанную в
                        соответствии с условиями Соглашения.
                    </li>
                    <li>
                        Для целей продления Срока действия лицензии на новый период
                        Лицензиар обязуется направить Лицензиату счет до истечения текущего
                        Срока действия лицензии.
                    </li>
                    <li>
                        Для продления Срока действия лицензии на новый период Лицензиат
                        обязуется оплатить Лицензиару вознаграждение за используемое
                        количество Лицензий в порядке предварительной оплаты не позднее, чем
                        за 10 (десять) календарных дней до истечения текущего Срока действия
                        лицензии.
                    </li>
                    <li>
                        Лицензиат уведомлен, что в случае неоплаты Лицензиатом
                        вознаграждения Лицензиара на новый Срок действия лицензии право
                        использования ПО прекращается по истечении текущего Срока действия
                        лицензии. Настоящим Лицензиат уведомлен, что в таком случае полный
                        функционал ПО автоматически отключается, у Лицензиата сохраняется
                        право использования программного обеспечения в пределах бесплатного
                        функционала.
                    </li>
                    <li>
                        Вознаграждение Лицензиара не подлежит возврату вне зависимости от
                        фактических объемов использования ПО Лицензиатом в течение Срока
                        действия лицензии.
                    </li>
                </ol>
                <br />
                <li>Акцепт Оферты и заключение Соглашения</li>
                <br />
                <ol>
                    <li>
                        Лицензиат производит Акцепт Оферты путем оплаты вознаграждения
                        Лицензиара, указанного в Счете с указанием количества лицензий и
                        условий использования (путем предварительной оплаты), в течение 2
                        (двух) рабочих дней с даты получения счета от Лицензиара.
                    </li>
                </ol>
                <br />
                <li>Срок действия и изменение условий Оферты</li>
                <br />
                <ol>
                    <li>
                        Оферта вступает в силу с даты, указанной в разделе «Дата вступления
                        в силу» и действует до момента отзыва Оферты Лицензиаром.
                    </li>
                    <li>
                        Лицензиар оставляет за собой право внести изменения в условия Оферты
                        (включая документы и/или отозвать Оферту в любой момент по своему
                        усмотрению. В случае внесения Лицензиаром изменений в Оферту, такие
                        изменения вступают в силу с момента размещения измененного текста
                        Оферты в сети Интернет по адресу{' '}
                        <a target="_blank" href="https://neuroplai.ru">
                            https://neuroplai.ru
                        </a>
                        , если иной срок вступления изменений в силу не определен
                        дополнительно при таком размещении.
                    </li>
                </ol>
                <br />
                <li>Срок действия и изменение Соглашения</li>
                <br />
                <ol>
                    <li>
                        Акцепт Оферты Лицензиатом, произведенный согласно ст. 6 Оферты,
                        создает Соглашение (согласно статье 438 Гражданского кодекса
                        Российской Федерации) на условиях Оферты.
                    </li>
                    <li>
                        Соглашение вступает в силу с момента Акцепта Оферты Лицензиатом и
                        действует в течение Срока действия лицензии.
                    </li>
                    <li>
                        Лицензиат соглашается и признает, что внесение изменений в Оферту
                        влечет за собой внесение этих изменений в заключенный и действующий
                        между Лицензиатом и Лицензиаром Соглашение, и эти изменения в
                        Соглашение вступают в силу одновременно с такими изменениями в
                        Оферту.
                    </li>
                    <li>
                        В случае отзыва Оферты Лицензиаром в течение срока действия
                        Соглашения, Соглашение считается прекращенным с момента отзыва, если
                        иное не оговорено Лицензиаром при отзыве Оферты.
                    </li>
                </ol>
                <br />
                <li>Расторжение Соглашения</li>
                <br />
                <ol>
                    <li>Соглашение может быть расторгнуто:</li>
                    <ol>
                        <li>
                            любой из Сторон в порядке одностороннего отказа от исполнения
                            Соглашения путём направления письменного уведомления другой
                            Стороне не менее чем за 30 (тридцать) рабочих дней до даты его
                            расторжения;
                        </li>
                        <li>
                            Лицензиаром в порядке одностороннего отказа от исполнения
                            Соглашения в случае нарушения Лицензиатом условий Соглашения
                            немедленно с письменным уведомлением Лицензиата;
                        </li>
                        <li>
                            по иным основаниям, предусмотренным настоящей Офертой и/или
                            действующим законодательством Российской Федерации.
                        </li>
                    </ol>
                    <li>
                        Обязательства Сторон по Соглашению, которые в силу своей природы
                        должны продолжать действовать (включая обязательства в отношении
                        конфиденциальности, проведения взаиморасчетов, использованию
                        информации, но, не ограничиваясь указанным), остаются в силе после
                        окончания действия Соглашения.
                    </li>
                    <li>
                        Прекращение действия Соглашения по любому основанию не освобождает
                        Стороны от ответственности за нарушения условий Соглашения,
                        возникшие в течение срока его действия.
                    </li>
                </ol>
                <br />
                <li>Гарантии</li>
                <br />
                <ol>
                    <li>
                        Лицензиар гарантирует, что предоставление Лицензиату прав на
                        использование Программного обеспечения по Соглашению не противоречит
                        законодательству, обязательствам, взятым на себя Лицензиаром перед
                        третьими лицами, и иным образом не нарушает прав и законных
                        интересов третьих лиц.
                    </li>
                    <li>
                        За исключением гарантий, прямо указанных в тексте Оферты и
                        документов, на которые содержатся ссылки в Оферте, Лицензиар не
                        предоставляет никаких иных прямых или подразумеваемых гарантий по
                        Соглашению и прямо отказывается от каких-либо гарантий или условий в
                        отношении Программного обеспечения и его соответствия конкретным
                        целям Лицензиата. Лицензиар предоставляет Программное обеспечение
                        «как есть» и не гарантирует его работоспособность.
                    </li>
                    <li>
                        Производя Акцепт Оферты, Лицензиат подтверждает и гарантирует
                        Лицензиару, что:
                    </li>
                    <ol>
                        <li>
                            Лицензиат указал достоверные данные, в том числе персональные
                            данные, при регистрации и достоверные данные, в том числе
                            персональные, Лицензиата для оформления документации.
                        </li>
                        <li>
                            Лицензиат дает согласие на обработку Лицензиаром персональных
                            данных, указанных Лицензиатом при регистрации и/или при заключении
                            Соглашения, в том числе на совершение Лицензиаром действий,
                            предусмотренных п. 3 ст. 3 Федерального закона от 27.07.2006 года
                            № 152-ФЗ «О персональных данных», любыми способами, для целей
                            заключения и исполнения Соглашения.
                        </li>
                        <li>
                            Лицензиат: а) полностью ознакомился с условиями Оферты, б)
                            полностью понимает предмет Оферты и Соглашения, в) полностью
                            понимает значение и последствия своих действий в отношении
                            заключения и исполнения Соглашения.
                        </li>
                        <li>
                            Лицензиат обладает всеми правами и полномочиями, необходимыми для
                            заключения и исполнения Соглашения.
                        </li>
                    </ol>
                </ol>
                <br />
                <li>Ответственность и ограничение ответственности</li>
                <br />
                <ol>
                    <li>
                        За нарушение условий Соглашения Стороны несут ответственность,
                        установленную Соглашением и/или действующим законодательством
                        Российской Федерации.
                    </li>
                    <li>
                        Лицензиар ни при каких обстоятельствах не несет никакой
                        ответственности по Соглашению за какие-либо косвенные убытки и/или
                        упущенную выгоду Лицензиата и/или третьих сторон вне зависимости от
                        того, мог Лицензиар предвидеть возможность таких убытков или нет.
                    </li>
                    <li>
                        В случае нарушения Лицензиатом условий предоставления права
                        использования Программного обеспечения, а именно способов
                        использования Программного обеспечения, Лицензиар вправе в
                        одностороннем порядке приостановить доступ Лицензиата к Программному
                        обеспечению или отказаться от исполнения Соглашения и потребовать
                        возмещения убытков, причиненных расторжением Соглашения.
                    </li>
                    <li>
                        В случае несоблюдения Лицензиаром гарантии, предусмотренной п. 10.1
                        Оферты, в результате которого Лицензиату будут предъявлены любыми
                        третьими лицами требования, претензии и/или иски, Лицензиар
                        обязуется урегулировать указанные требования, претензии и/или иски
                        своими силами и за свой счет, а также возместить Лицензиату
                        понесённый им в связи с этим реальный ущерб.
                    </li>
                    <li>
                        В любом случае ответственность Лицензиара по Соглашению, в том числе
                        возмещение убытков по любому основанию, ограничивается общей суммой
                        платежей, полученных от Лицензиата за последние 12 (двенадцать)
                        календарных месяцев, предшествующих основанию возникновения
                        ответственности.
                    </li>
                    <li>
                        Уплата неустойки и возмещение ущерба не освобождает Стороны от
                        исполнения обязательств по Соглашению. Уплата неустойки и возмещение
                        убытков производятся исключительно на основании письменной претензии
                        заинтересованной Стороны.
                    </li>
                    <li>
                        Стороны освобождаются от ответственности за частичное или полное
                        неисполнение обязательств по Соглашению, если это неисполнение
                        явилось следствием обстоятельств непреодолимой силы, которые
                        возникли после заключения Соглашения, либо если неисполнение
                        обязательств Сторонами по Соглашению явилось следствием событий
                        чрезвычайного характера, которые Стороны не могли ни предвидеть, ни
                        предотвратить разумными мерами.
                    </li>
                </ol>
                <br />
                <li>Конфиденциальность</li>
                <br />
                <ol>
                    <li>
                        В рамках Соглашения понятие «конфиденциальная информация» включает,
                        в том числе, организационно-технологическую, коммерческую,
                        финансовую и иную информацию, связанную с заключением и исполнением
                        Сторонами Соглашения.
                    </li>
                    <li>
                        Опубликование любых упоминаний о Лицензиаре, сообщение третьим лицам
                        или неопределенному кругу лиц о факте и/или деталях сотрудничества в
                        связи с Соглашением производится исключительно по предварительному
                        письменному согласию Лицензиара, кроме случаев, когда предоставление
                        соответствующей информации обязательно в соответствии с действующим
                        законодательством.
                    </li>
                    <li>
                        Обязательства по соблюдению конфиденциальности не распространяются
                        на общедоступную информацию или информацию, которая становится
                        публично известной по обстоятельствам, независящим от Сторон.
                    </li>
                    <li>
                        В случае умышленного или неосторожного разглашения конфиденциальной
                        информации виновная Сторона обязуется возместить по требованию
                        пострадавшей Стороны, причиненные этим разглашением убытки.
                    </li>
                    <li>
                        Срок охраны конфиденциальной информации ограничивается Сторонами
                        сроком в 3 (три) года с момента окончания действия Соглашения. При
                        этом обязательство Лицензиара по неразглашению информации,
                        содержащейся в Запросах, является бессрочным.
                    </li>
                    <li>
                        Стороны согласились, что условие сохранения конфиденциальности
                        регистрационных данных (включая персональные данные), указанных
                        Лицензиатом при регистрации и/или при заключении Соглашения, не
                        распространяется на случаи использования Лицензиаром таких данных в
                        целях оформления документации. В указанных документах подлежат
                        указанию данные (в том числе персональные данные) и реквизиты,
                        предоставленные Лицензиатом.
                    </li>
                </ol>
                <br />
                <li>Прочие условия</li>
                <br />
                <ol>
                    <li>
                        Оферта, Соглашение, его заключение и исполнение регулируется
                        действующим законодательством Российской Федерации. Все вопросы, не
                        урегулированные Офертой или урегулированные не полностью,
                        регулируются в соответствии с материальным правом Российской
                        Федерации.
                    </li>
                    <li>
                        В случае неурегулирования спора путем переговоров Стороны
                        устанавливают обязательный досудебный претензионный порядок
                        разрешения спора.
                    </li>
                    <li>
                        В случае получения претензии любая из Сторон обязана в течение 20
                        (двадцати) рабочих дней с даты ее получения рассмотреть претензию и
                        представить другой Стороне предложения по ее урегулированию с
                        указанием сроков урегулирования.
                    </li>
                    <li>
                        В случае если Стороны не придут к согласию по спорным вопросам в
                        течение срока рассмотрения претензии, указанного в п. 13.3 Оферты (в
                        том числе при отсутствии ответа на претензию и получении отказа в
                        удовлетворении претензии), спор подлежит рассмотрению в суде по
                        месту нахождения Лицензиара.
                    </li>
                    <li>
                        Любые уведомления по Соглашению могут направляться одной Стороной
                        другой Стороне: 1) по электронной почте а) на адрес электронной
                        почты Лицензиата, указанный им при регистрации, с адреса электронной
                        почты Лицензиара, указанного в п.14 Оферты в случае, если
                        получателем является Лицензиат, и б) на адрес электронной почты
                        Лицензиара, указанный в п.14 Оферты, с адреса электронной почты
                        Лицензиата, указанного им в Личном кабинете на Сайте.{' '}
                    </li>
                    <li>
                        В случае если одно или более положений Оферты (Соглашения) являются
                        по какой-либо причине недействительными, не имеющими юридической
                        силы, такая недействительность не оказывает влияния на
                        действительность любого другого положения Оферты (Соглашения),
                        которые остаются в силе.
                    </li>
                    <li>
                        Стороны соблюдают все применимые нормы антикоррупционного
                        законодательства. Стороны признают и подтверждают, что каждая из них
                        проводит политику полной нетерпимости к взяточничеству и коррупции,
                        предполагающую полный запрет коррупционных действий и совершения
                        выплат за содействие / выплат, целью которых является упрощение
                        формальностей в связи с хозяйственной деятельностью, обеспечение
                        более быстрого решения тех или иных вопросов.
                    </li>
                    <p style={{ textIndent: 0 }}>
                        Стороны, их аффилированные лица, работники, а также посредники и
                        представители, которые прямо или косвенно участвуют в исполнении
                        обязательств Сторонами (в том числе агенты, комиссионеры, таможенные
                        брокеры и иные третьи лица) не принимают, не выплачивают, не
                        предлагают выплатить и не разрешают (санкционируют)
                        выплату/получение каких-либо денежных средств или передачу
                        каких-либо ценностей (в том числе нематериальных) прямо или
                        косвенно, любым лицам, с целью оказания влияния на действия или
                        решения с намерением получить какие-либо неправомерные преимущества,
                        в том числе в обход установленного законодательством порядка, или
                        преследующие иные неправомерные цели.
                    </p>
                    <p style={{ textIndent: 0 }}>
                        Положения настоящего пункта являются заверениями об обстоятельствах,
                        имеющими для Сторон существенное значение. Стороны полагаются на
                        такие заверения при заключении Соглашения.
                    </p>
                    <p style={{ textIndent: 0 }}>
                        В случае нарушения одной из Сторон обязательств по соблюдению
                        требований, предусмотренных настоящим пунктом, Сторона вправе
                        немедленно отказаться от Соглашения в одностороннем внесудебном
                        порядке, направив письменное уведомление о расторжении. Соглашение
                        считается расторгнутым по истечении 10 (десяти) календарных дней с
                        даты получения другой Стороной соответствующего письменного
                        уведомления.
                    </p>
                    <p style={{ textIndent: 0 }}>
                        В случае возникновения у Стороны подозрений, что произошло или может
                        произойти нарушение каких-либо положений настоящего пункта,
                        соответствующая Сторона обязуется как можно скорее уведомить другую
                        Сторону о своих подозрениях в письменной форме.
                    </p>
                </ol>
                <br />
                <li style={{ textIndent: 0 }}>Реквизиты Лицензиара:</li>
                <br />
                <p style={{ textIndent: 0 }}>Акционерное общество “Нейросети” </p>
                <p style={{ textIndent: 0 }}>ОГРН 119774673055</p>
                <p style={{ textIndent: 0 }}>ИНН/КПП 7733349229/771001001</p>
                <p style={{ textIndent: 0 }}>
                    Адрес: 123056, г. Москва, Грузинский Вал, д. 26 строение 3, этаж 1,
                    помещение II (два)
                </p>
                <p style={{ textIndent: 0 }}>р/с № 40702810902830004492,</p>
                <p style={{ textIndent: 0 }}>в АО «Альфа Банк», БИК 044525593</p>
                <p style={{ textIndent: 0 }}>к/с № 30101810200000000593</p>
                <p style={{ textIndent: 0 }}>Тел.: +79384735079</p>
                <p style={{ textIndent: 0 }}>
                    E-mail: <a target="_blank" href="mailto:neiroseti@mail.ru"></a>
                    neiroseti@mail.ru
                </p>
            </ol>
        </Wrapper>
    );
};
