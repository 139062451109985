import styled from '@emotion/styled';
import { TFunction } from 'i18next';
import { FC, useCallback, useDeferredValue, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLoadAllAnalytics } from '../../../hooks/use-load-all-analytics';
import { LocaleKeys } from '../../../locale';
import {
  useAnalyticsFilterSelector,
  useDynamicsDataSelector,
} from '../../../state/analytics/selectors';
import { timeToString } from '../../../utils/common';
import { sortAnalyticsTable } from '../../../utils/sort-functions/sort-analytics-table';
import { Table } from '../../table';
import { TSortValue, TableColumn, TableRowData } from '../../table/types';
import { TableHeader } from './table-header-component';
import { TableLabelComponent } from './table-label-component';
import { TableProgress } from './table-progress';
import { TAnalyticsDataType } from './types';

const Wrapper = styled.div`
  margin-top: 16px;
`;

const getColumns: (
  t: TFunction<string[]>
) => TableColumn<TAnalyticsDataType>[] = (t) => [
  {
    key: 'name',
    label: t('table.columns.nameLabel'),
    columnComponent: TableHeader,
    sortable: true,
    tooltip: t('table.columns.nameTooltip'),
    renderFunc: (_, data, expanded, canBeExpanded, childIndex) => {
      return (
        <TableLabelComponent
          label={data.name}
          expanded={expanded}
          canBeExpanded={canBeExpanded}
          childIndex={childIndex}
          linkId={data.linkId}
          videoId={data.videoId}
          linkStatTooltip={t('table.linkStatTooltip')}
          email={data.email}
          userId={data.userId}
        />
      );
    },
  },
  {
    key: 'time',
    label: t('table.columns.timeLabel'),
    tooltip: t('table.columns.timeTooltip'),
    columnComponent: TableHeader,
    sortable: true,
  },
  {
    key: 'views',
    label: t('table.columns.viewsLabel'),
    tooltip: t('table.columns.viewsTooltip'),
    columnComponent: TableHeader,
    sortable: true,
  },
  {
    key: 'totalViews',
    label: t('table.columns.totalViewsLabel'),
    tooltip: t('table.columns.totalViewsTooltip'),
    columnComponent: TableHeader,
    sortable: true,
  },
  {
    key: 'attentiveness',
    label: t('table.columns.attentivenessLabel'),
    tooltip: t('table.columns.attentivenessTooltip'),
    columnComponent: TableHeader,
    sortable: true,
    renderFunc: (value) => {
      return <TableProgress value={value as number} color={'#1871F8'} />;
    },
  },
  {
    key: 'engagement',
    label: t('table.columns.engagementLabel'),
    tooltip: t('table.columns.engagementTooltip'),
    columnComponent: TableHeader,
    sortable: true,
    renderFunc: (value) => {
      return <TableProgress value={value as number} color={'#B46AEE'} />;
    },
  },
  {
    key: 'emotionality',
    label: t('table.columns.emotionalityLabel'),
    tooltip: t('table.columns.emotionalityTooltip'),
    columnComponent: TableHeader,
    sortable: true,
    renderFunc: (value) => {
      return <TableProgress value={value as number} color={'#D8B126'} />;
    },
  },
  {
    key: 'fatigue',
    label: t('table.columns.fatigueLabel'),
    tooltip: t('table.columns.fatigueTooltip'),
    columnComponent: TableHeader,
    sortable: true,
    renderFunc: (value) => {
      return <TableProgress value={value as number} color={'#888C9D'} />;
    },
  },
  {
    key: 'absent_mindedness',
    label: t('table.columns.absent_mindednessLabel'),
    tooltip: t('table.columns.absent_mindednessTooltip'),
    columnComponent: TableHeader,
    sortable: true,
    renderFunc: (value) => {
      return <TableProgress value={value as number} color={'#FA5555'} />;
    },
  },
];

export const AnalyticsTable: FC = () => {
  const { data, pending } = useLoadAllAnalytics();
  const { pending: dynamicsPending } = useDynamicsDataSelector();
  const { linkId, userId, videosId } = useAnalyticsFilterSelector();
  const { t } = useTranslation([LocaleKeys.ANALYTICS]);

  const [sortedData, setSortedData] = useState<
    Partial<Record<keyof TAnalyticsDataType, TSortValue>>
  >({});

  const tableData: TableRowData<TAnalyticsDataType>[] = useMemo(() => {
    let result: TableRowData<TAnalyticsDataType>[] = [];

    data?.videosStats
      .filter((el) => {
        const videoLinks = data?.linksStats.filter(
          (linkEl) => linkEl.videoId === el.videoId
        );

        if (!videoLinks || videoLinks.length === 0) {
          return false;
        }

        return videosId && videosId.length > 0
          ? videosId.includes(el.videoId)
          : true;
      })
      .map((videoEl) => {
        const videoLinks = data?.linksStats.filter(
          (linkEl) => linkEl.videoId === videoEl.videoId
        );

        let linksDuration: Record<string, number> = {};

        const videoViewDuration: number = videoLinks.reduce((sum, acc) => {
          const linkDuration = data?.usersLinksStats[acc.linkId].reduce(
            (usersSum, usersAcc) => {
              return usersSum + usersAcc.viewingDuration;
            },
            0
          );
          linksDuration = { ...linksDuration, [acc.linkId]: linkDuration };
          return sum + linkDuration;
        }, 0);

        result.push({
          id: videoEl.videoId,
          data: {
            name: videoEl.title,
            time: timeToString(videoViewDuration),
            views: videoEl.views,
            totalViews: null,
            attentiveness: videoEl.attentiveness,
            engagement: videoEl.engagement,
            emotionality: videoEl.emotionality,
            fatigue: videoEl.fatique,
            absent_mindedness: videoEl.absent_mindedness,
            videoId: videoEl.videoId,
          },

          children: videoLinks
            .filter((linkEl) =>
              linkId && linkId.length > 0
                ? linkId.includes(linkEl.linkId)
                : true
            )
            .map((linkEl) => ({
              id: linkEl.linkId,
              data: {
                name: linkEl.name,
                time: timeToString(linksDuration[linkEl.linkId]),
                views: linkEl.views,
                totalViews: data?.usersLinksStats[linkEl.linkId]?.length ?? 0,
                attentiveness: linkEl.attentiveness,
                engagement: linkEl.engagement,
                emotionality: linkEl.emotionality,
                fatigue: linkEl.fatique,
                absent_mindedness: linkEl.absent_mindedness,
                linkId: linkEl.linkId,
                videoId: linkEl.videoId,
                is_hidden_users_stats: false,
              },

              children: data?.usersLinksStats[linkEl.linkId]
                .filter((userEl) =>
                  userId && userId.length > 0
                    ? userId.includes(userEl.userId)
                    : true
                )
                .map((userEl) => ({
                  id: userEl.userId,
                  data: {
                    name: !!userEl.fullname?.trim()
                      ? userEl.fullname
                      : t('base.noName'),
                    time: timeToString(userEl.viewingDuration),
                    views: userEl.viewsCount,
                    totalViews: null,
                    attentiveness: userEl.attentiveness,
                    engagement: userEl.engagement,
                    emotionality: userEl.emotionality,
                    fatigue: userEl.fatique,
                    absent_mindedness: userEl.absent_mindedness,
                    userId: userEl.userId,
                    email: userEl.email,
                    linkId: userEl.linkId,
                    videoId: userEl.videoId,
                    is_hidden_users_stats: linkEl.is_hidden_users_stats,
                  },
                })),
            })),
        });
      });

    if (sortedData && Object.keys(sortedData).length > 0) {
      result.sort((a, b) => sortAnalyticsTable(a, b, sortedData));

      result.map((resMap) => {
        if (resMap.children) {
          resMap.children.sort((a, b) => sortAnalyticsTable(a, b, sortedData));

          resMap.children.map((resThirdLevel) => {
            if (resThirdLevel.children) {
              resThirdLevel.children.sort((a, b) =>
                sortAnalyticsTable(a, b, sortedData)
              );
            }
          });
        }

        return resMap;
      });
    }

    return result;
  }, [data, videosId, linkId, userId, t, sortedData]);

  const deferredData = useDeferredValue(tableData);

  const handleSetSortedData = useCallback(
    (data: Partial<Record<keyof TAnalyticsDataType, TSortValue>>) => {
      setSortedData(() => ({
        ...data,
      }));
    },
    []
  );

  const columns = useMemo(() => getColumns(t), [t]);

  return (
    <Wrapper>
      <Table
        columns={columns}
        rows={deferredData}
        loading={pending || dynamicsPending}
        emptyText={t('table.tableEmpty')}
        sortedData={sortedData}
        setSortedData={handleSetSortedData}
      />
    </Wrapper>
  );
};
