import { LoadingOutlined } from '@ant-design/icons';
import App from 'antd/lib/app';
import { AxiosResponse } from 'axios';
import {
  FC,
  FormEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';

import {
  completeMultipartUploadAsync,
  initMultipartUpload,
  uploadChunkAsync,
} from '../../api/uploadFileWithChunks';
import { PageHeader } from '../../components/common/page-header';
import { LS_LAYOUT_VALUE_LEY } from '../../components/common/title-with-layout';
import { UploadVideoVariantsPopover } from '../../components/common/upload-video-variants-popover';
import AddVideo from '../../components/icons/add-video';
import { Content } from '../../components/layout/upload-video-layout';
import { UploadProgressBar } from '../../components/upload-progress';
import { NotificationsTrafficLimit } from '../../components/uploaded-videos/NotificationsTrafficLimit';
import { PageMenu } from '../../components/uploaded-videos/page-menu';
import { Playlists } from '../../components/uploaded-videos/playlists';
import { Videos } from '../../components/uploaded-videos/videos';
import { useHandleLoadPlaylist } from '../../hooks/playlists';
import { useLocalStorageValue } from '../../hooks/use-local-storage-value';
import { usePlaylistHandleSave } from '../../hooks/use-playlist-handle-save';
import { LocaleKeys } from '../../locale';
import { PlaylistCreateModal } from '../../modals/video/playlist-create-modal';
import { UploadWithLinkModal } from '../../modals/video/upload-with-link-modal';
import { useAppDispatch } from '../../state';
import { useMeTariffLimits, useMeTariffPlan } from '../../state/me/selectors';
import { usePlaylistCurrentSelector } from '../../state/playlists/selectors';
import { setProgress } from '../../state/upload-progress/uploadProgressSlice';
import {
  TFirstStage,
  createVideoNextStageAction,
  createVideoResetStateAction,
  createVideoSetFirstStageAction,
} from '../../state/video/createSlice';
import { useCreateVideoSelector } from '../../state/video/selectors';
import { Paths, SecondaryPaths } from '../../types/common';
import {
  ESortOption,
  EVideoQueryMods,
  EVideoQueryPaths,
} from '../../types/video';
import { useGetVideoDuration } from '../../utils/useGetVideoDuration';
import { useWidth } from '../../utils/useWidth';
import { UploadResponse, VideoItem } from './types';
import {
  AddVideoButton,
  ContentWrapper,
  FlexTitle,
  LoadingWrapper,
  TableNotificationsContainer,
  Title,
} from './uploaded-videos.styled';

export const UploadedVideos: FC = () => {
  const [withSaving, setWithSaving] = useState(false);
  const [sortOption, setSortOption] = useState<ESortOption>(ESortOption.NEW);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [playListModal, setPlayListModal] = useState(false);
  const [playlistEditId, setPlayListEditId] = useState<string | null>(null);
  const [playlistEditMode, setPlayListEditMode] = useState(false);
  const [bandwidthRestPercent, setBandwidthRestPercent] = useState(100);
  const [loadWithLinkModalOpen, setLoadWithLinkModalOpen] = useState(false);

  const { t } = useTranslation([LocaleKeys.VIDEO]);
  const { width } = useWidth();

  const tariffPlan = useMeTariffPlan();
  const tariffLimits = useMeTariffLimits();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { message } = App.useApp();

  const { currentStage } = useCreateVideoSelector();

  const { data, loaded, pending } = usePlaylistCurrentSelector();

  const [searchParams] = useSearchParams();

  const [layout, setLayout] = useLocalStorageValue<'col' | 'grid'>(
    'grid',
    LS_LAYOUT_VALUE_LEY
  );

  const [
    handleSavePlaylist,
    operationProps,
    exitEditMode,
    editSelected,
    setEditSelected,
  ] = usePlaylistHandleSave();

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChangeVideoList = useCallback((videoId: string) => {
    setEditSelected((prev) => {
      if (prev.includes(videoId)) {
        return prev.filter((el) => el !== videoId);
      } else {
        return [...prev, videoId];
      }
    });
  }, []);

  const handleLoadPlaylist = useHandleLoadPlaylist();

  useEffect(() => {
    const playlistId = searchParams.get(EVideoQueryPaths.PLAYLIST_ID);
    const mode = searchParams.get(EVideoQueryPaths.MODE);

    if (mode === EVideoQueryMods.ADD_VIDEO && playlistId) {
      setPlayListEditMode(true);
      setPlayListEditId(playlistId);
    } else {
      setPlayListEditMode(false);
      setPlayListEditId(null);
    }
  }, [searchParams]);

  useLayoutEffect(() => {
    if (currentStage === 'second') {
      navigate(Paths.VIDEO_CREATE);
    }
  }, [currentStage]);

  const handleSaveStage = useCallback((data: Partial<TFirstStage>) => {
    dispatch(createVideoSetFirstStageAction(data));
  }, []);

  const initUploadWithChunks = useCallback(
    async (fileName: string, fileType: string, fileSize: number) => {
      try {
        dispatch(setProgress({ progress: 0, visible: true }));
        setIsUploading(true);
        const responseInitUpload: AxiosResponse<UploadResponse> =
          await initMultipartUpload(fileName, fileType, fileSize);

        const key = responseInitUpload.data?.key;
        const uploadId = responseInitUpload.data?.uploadId;

        if (key && uploadId) {
          return { key, uploadId };
        }

        return { key: undefined, uploadId: undefined };
      } catch (err: any) {
        if (
          err?.response?.data?.message.includes(
            'The video size limit for the tariff plan has been exceeded'
          )
        ) {
          message.error(t('upload.errorSizeLimit'));
        } else {
          message.error(t('upload.error'));
        }
        dispatch(setProgress({ progress: 0, visible: false }));
        return { key: undefined, uploadId: undefined };
      }
    },
    []
  );
  const completeUpload = useCallback(
    async (
      uploadId: string,
      key: string,
      videoItem: VideoItem,
      fileDuration: number
    ) => {
      try {
        const response: AxiosResponse = await completeMultipartUploadAsync({
          uploadId,
          key,
        });

        if ([200, 201].includes(response.status)) {
          message.success(t('upload.success'));
          handleSaveStage({
            videoDuration: fileDuration,
            uploadResponse: { ...response.data },
            uploaded: true,
            pending: false,
            filled: true,
          });
          dispatch(setProgress({ progress: 0, visible: false }));
          dispatch(createVideoNextStageAction());
          videoItem.remove();
        } else {
          throw new Error('Failed to complete upload');
        }
      } catch (error) {
        console.error('Error completing upload:', error);
        message.error(t('upload.error'));
        handleSaveStage({ pending: false });
      }
    },
    []
  );

  const uploadChunks = useCallback(
    async (
      key: string,
      uploadId: string,
      file: File,
      videoItem: VideoItem,
      fileDuration: number
    ) => {
      const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB
      const totalChunks = Math.ceil(file.size / CHUNK_SIZE); // Общее количество чанков
      let chunkIndex = 0; // Индекс текущего чанка

      const uploadChunk = async (startByte: number, endByte: number) => {
        const chunk = file.slice(startByte, endByte); // Получаем текущий чанк
        const fdChunk = new FormData();

        fdChunk.append('chunk', chunk);
        fdChunk.append('uploadId', uploadId);
        fdChunk.append('partNumber', String(chunkIndex + 1));
        fdChunk.append('key', key);

        try {
          const response = await uploadChunkAsync(fdChunk); // Загружаем чанк

          if (![200, 201].includes(response.status)) {
            throw new Error('Failed to upload chunk');
          }

          // Обновляем прогресс
          chunkIndex++;
          const newProgress = Math.round((chunkIndex / totalChunks) * 100);
          dispatch(setProgress({ progress: newProgress, visible: true }));
          // Если есть еще чанки, загружаем следующий
          if (chunkIndex < totalChunks) {
            await uploadChunk(
              startByte + CHUNK_SIZE,
              Math.min(startByte + 2 * CHUNK_SIZE, file.size)
            );
          } else {
            // Завершаем загрузку
            await completeUpload(uploadId, key, videoItem, fileDuration);
          }
        } catch (error) {
          console.error('Chunk upload error:', error);
          dispatch(setProgress({ progress: 0, visible: false }));
        }
      };

      // Начинаем загрузку с первого чанка
      await uploadChunk(0, Math.min(CHUNK_SIZE, file.size));
    },
    [t, completeUpload]
  );

  const handleUploadVideo = useCallback(
    async (fileFormData: FormData) => {
      try {
        handleSaveStage({ pending: true });

        const videoItem = document.createElement('video');
        videoItem.preload = 'metadata';

        const file = fileFormData.get('file') as File | null;

        if (!file) {
          handleSaveStage({ pending: false });
          return;
        }

        const canPlay = videoItem.canPlayType(file.type);

        if (!canPlay) {
          handleSaveStage({ pending: false });
          message.error(t('upload.unsupportedFormat'));
          return;
        }

        const fileDuration = await useGetVideoDuration(file);

        const fileSize = file.size;

        handleSaveStage({ file });

        const { key, uploadId } = await initUploadWithChunks(
          file.name,
          file.type,
          fileSize
        );

        if (key && uploadId) {
          await uploadChunks(key, uploadId, file, videoItem, fileDuration);
        } else {
          handleSaveStage({ pending: false });
        }
      } catch (error) {
        console.error('Video error:', error);
        handleSaveStage({ pending: false });
      }
    },
    [t]
  );

  const handleChange = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      const target = e.target as HTMLInputElement;
      if (target.files && target.files.item(0)) {
        const file = target.files.item(0);

        if (file) {
          const formData = new FormData();
          formData.set('file', file);
          formData.set('mediaType', 'video');

          if (file.size < 2147483648) {
            void handleUploadVideo(formData);
          } else {
            void message.error(t('upload.sizeLimitError'));
          }

          if (inputRef.current) {
            // @ts-ignore
            inputRef.current.value = null;
          }
        }
      }
      dispatch(createVideoResetStateAction());
    },
    [t]
  );

  useEffect(() => {
    if (
      playlistEditMode &&
      playlistEditId &&
      ((!pending && !loaded) || (loaded && data?.id !== playlistEditId))
    ) {
      void handleLoadPlaylist(playlistEditId);
    }
  }, [loaded, pending, data, playlistEditId, playlistEditMode]);

  useEffect(() => {
    if (data && playlistEditMode && !pending) {
      setEditSelected(data.videos?.map((el) => el.id) ?? []);
    }
  }, [data, playlistEditMode, pending]);

  const handleUploadByLink = useCallback(() => {
    setWithSaving(true);
    setLoadWithLinkModalOpen(true);
  }, []);

  const handleLoadLinkWithoutUpload = useCallback(() => {
    setWithSaving(false);
    setLoadWithLinkModalOpen(true);
  }, []);

  useEffect(() => {
    if (tariffLimits && tariffPlan) {
      const percentRest =
        (tariffLimits.bandwidth_rest * 100) / tariffPlan.bandwidth;

      setBandwidthRestPercent(percentRest);
    }
  }, [tariffLimits, tariffPlan]);

  return (
    <div>
      <input
        type="file"
        hidden
        id="add-video-file"
        ref={inputRef}
        onChange={handleChange}
        accept="video/*"
      />

      <PageHeader
        rightAddon={
          <UploadVideoVariantsPopover
            onUploadWithLinkClick={handleUploadByLink}
            onWithoutUploadClick={handleLoadLinkWithoutUpload}
          >
            <AddVideoButton>
              <AddVideo />
              {t('videos.download.title')}
            </AddVideoButton>
          </UploadVideoVariantsPopover>
        }
      >
        <FlexTitle>
          <Title>{t('videos.uploaded')}</Title>
          {width > 948 && bandwidthRestPercent < 20 && (
            <NotificationsTrafficLimit />
          )}
        </FlexTitle>
      </PageHeader>

      {width <= 948 && bandwidthRestPercent < 20 && (
        <TableNotificationsContainer>
          <NotificationsTrafficLimit />
        </TableNotificationsContainer>
      )}

      <PageMenu
        playlistEditMode={playlistEditMode}
        setPlayListModal={setPlayListModal}
        handleSavePlaylist={handleSavePlaylist}
        exitEditMode={exitEditMode}
        operationsButtonProps={operationProps}
        sortOption={sortOption}
        setSortOption={setSortOption}
      />

      {playlistEditMode ? (
        <ContentWrapper layout={layout}>
          {pending && (
            <LoadingWrapper>
              <LoadingOutlined />
            </LoadingWrapper>
          )}
          <Videos
            playlistEditMode={playlistEditMode}
            handleChangeVideoList={handleChangeVideoList}
            selectedVideos={editSelected}
            sortOption={sortOption}
            layout={layout}
          />
        </ContentWrapper>
      ) : (
        <Routes>
          <Route element={<Content layout={layout} />}>
            <Route
              path={SecondaryPaths.BASE}
              element={<Videos layout={layout} sortOption={sortOption} />}
            />
            <Route
              path={SecondaryPaths.PLAYLISTS}
              element={<Playlists layout={layout} sortOption={sortOption} />}
            />
          </Route>
        </Routes>
      )}

      <PlaylistCreateModal open={playListModal} setOpen={setPlayListModal} />

      <UploadWithLinkModal
        open={loadWithLinkModalOpen}
        setOpen={setLoadWithLinkModalOpen}
        withSaving={withSaving}
      />

      {isUploading && <UploadProgressBar />}
    </div>
  );
};
