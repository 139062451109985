import styled from '@emotion/styled';
import { message } from 'antd';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LocaleKeys } from '../../locale';
import { boolToText, textToBool } from '../../utils/common';
import { VideoPlay } from '../icons/video-play';
import { TimeLineControl } from '../video-page/time-line-control';
import { Controls } from './controls';

type TProps = {
  videoSrc: string;
  className?: string;
  preview?: {
    url: string;
    alt?: string;
  };
  LineVisible?: boolean;
};

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Wrapper = styled('div') <{ controlsVisible: 'true' | 'false' }>`
  width: 100%;
  height: 0;
  padding-top: 56%;
  position: relative;
  overflow: hidden;
  border-radius: 20px;

  video {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: ${({ controlsVisible }) =>
    textToBool(controlsVisible) ? 'pointer' : 'none'};
  }
`;

const SetControls = styled('div')`
  display: flex;
  align-items: start;
  gap: 20px;

  & > div:last-child {
    flex-grow: 1;
    padding-top: 18px;
  }
`;
const PreviewWrapper = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 110%;
    height: 110%;
    object-fit: cover;
  }
`;
const PlayButton = styled('div')`
  display: flex;
  width: 100px;
  height: 100px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  pointer-events: none;
`;

export const VideoPlayer = forwardRef<HTMLVideoElement, TProps>(
  ({ videoSrc, className, preview, LineVisible = true }, ref) => {
    const wrapperRef = useRef<HTMLDivElement | null>(null);

    const [isZeroTiming, setZeroTiming] = useState(true);
    const [play, setPlay] = useState(false);
    const [controlsVisible, setControlsVisible] = useState(true);
    const [isVideoError, setVideoError] = useState(false);
    const [isVideoLoaded, setVideoLoaded] = useState(false); // Для отслеживания загрузки видео
    const [isVideoLoading, setVideoLoading] = useState(true); // Для отслеживания состояния загрузки

    const visibleTimeoutRef = useRef<NodeJS.Timeout>();

    const { t } = useTranslation([LocaleKeys.VIDEO]);

    const togglePlay = useCallback(() => {
      if (isVideoError || !isVideoLoaded) {
        // Блокируем попытки воспроизведения, если есть ошибка или видео не загружено
        message.error(t('newVideo.errNoLoadedVideo'));
        return;
      }

      try {
        if (play) {
          setPlay(false);

          if (ref && 'current' in ref) {
            try {
              void ref.current?.pause();
            } catch (err) {
              console.error('Ошибка при попытке паузы видео:', err);
              message.error(t('newVideo.noStopVideo'));
            }
          }
        } else {
          if (ref && 'current' in ref) {
            try {
              void ref.current?.play();
            } catch (err) {
              console.error('Ошибка при воспроизведении видео:', err);
              message.error(t('newVideo.noPlayVideo'));
              setPlay(false);
            }
          }
        }
      } catch (err) {
        console.error('Ошибка обработки воспроизведения:', err);
        message.error(t('newVideo.errLoadVideo'));
      }
    }, [play, isVideoError, isVideoLoaded, ref]);

    const handleMouseOver = useCallback(() => {
      if (!controlsVisible) {
        setControlsVisible(true);

        if (visibleTimeoutRef.current) {
          clearTimeout(visibleTimeoutRef.current);
        }
      }
    }, [controlsVisible]);

    useEffect(() => {
      if (controlsVisible) {
        if (visibleTimeoutRef.current) {
          clearTimeout(visibleTimeoutRef.current);
        }

        visibleTimeoutRef.current = setTimeout(() => {
          setControlsVisible(false);
        }, 3000);
      }
    }, [controlsVisible]);

    useEffect(() => {
      // Очистка состояний при смене видео
      setPlay(false);
      setZeroTiming(true);
      setVideoError(false);
      setVideoLoaded(false);
      setVideoLoading(true); // Включаем состояние загрузки

      if (ref && 'current' in ref && ref.current) {
        ref.current.onended = () => {
          setPlay(false);
          setZeroTiming(true);
        };

        ref.current.onpause = () => {
          setPlay(false);
        };

        ref.current.onplay = () => {
          setPlay(true);
          setZeroTiming(false);
        };

        ref.current?.addEventListener('timeupdate', () => {
          setZeroTiming(false);
        });
      }

      // Очистка эффекта при размонтировании или изменении видео
      return () => {
        if (ref && 'current' in ref && ref.current) {
          ref.current.removeEventListener('timeupdate', () => {
            setZeroTiming(false);
          });
        }
      };
    }, [videoSrc]);

    const handleVideoLoadedMetadata = () => {
      setVideoLoading(false); // Видео загружено
      setVideoLoaded(true); // Видео готово к воспроизведению
    };

    const handleVideoError = () => {
      console.error('Ошибка загрузки видео');
      message.error(t('newVideo.errorPlayVideoCheckSource'));
      setVideoError(true);
      setPlay(false);
      setVideoLoading(false); // Останавливаем загрузку
    };

    return (
      <MainWrapper>
        <Wrapper
          className={className}
          ref={wrapperRef}
          onMouseMove={handleMouseOver}
          controlsVisible={boolToText(controlsVisible)}
        >
          <video
            src={videoSrc}
            controls={false}
            ref={ref}
            preload="auto" // Начать загрузку видео сразу
            onClick={togglePlay}
            crossOrigin="anonymous"
            onLoadedMetadata={handleVideoLoadedMetadata} // Срабатывает, когда метаданные видео загружены
            onError={handleVideoError} // Обработка ошибки загрузки
          />

          {preview && isZeroTiming && (
            <PreviewWrapper>
              <img src={preview.url} alt={preview.alt} />
            </PreviewWrapper>
          )}

          {!play && !isVideoError && !isVideoLoaded && !isVideoLoading && (
            <PlayButton>
              <VideoPlay />
            </PlayButton>
          )}
        </Wrapper>
        <SetControls>
          <Controls
            play={play}
            togglePlay={togglePlay}
            ref={ref}
            controlsVisible={controlsVisible || !play}
            isVideoError={isVideoError}
          />
          {LineVisible && <TimeLineControl ref={ref} />}
        </SetControls>
      </MainWrapper>
    );
  }
);
