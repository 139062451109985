import { FunctionComponent, ReactNode } from 'react';

export const SORT_VALUES = ['asc', 'desc', false] as const;

export type TSortValue = (typeof SORT_VALUES)[number];

export interface TableColumn<T extends object> {
  key: keyof T;
  label?: string;
  sortable?: boolean;
  tooltip?: string;
  renderFunc?: (
    value: T[keyof T],
    data: T,
    expanded: boolean,
    canBeExpanded: boolean,
    childIndex: number, // Глубина дочернего элемента (0 - главный, 1-N - дочерние)
    rowHovered: boolean
  ) => ReactNode;
  columnComponent?: FunctionComponent<{
    label?: string;
    sorted?: TSortValue;
    tooltip?: string;
    setSorted?: (newValue: Partial<Record<keyof T, TSortValue>>) => void;
    columnKey: keyof T;
    sortable?: boolean;
  }>;
}

export interface TableRowData<T extends object> {
  id: string;
  data: T;
  children?: TableRowData<T & { is_hidden_users_stats: boolean }>[];
}

export interface TableProps<T extends object> {
  columns: TableColumn<T>[];
  rows: TableRowData<T>[];
  loading?: boolean;
  emptyText?: string;
  sortedData: Partial<Record<keyof T, TSortValue>>;
  setSortedData: (newValue: Partial<Record<keyof T, TSortValue>>) => void;
}
