export const covertImageToBlob = async (imageUrl: string) => {
    try {
        const response = await fetch(imageUrl);

        if (!response.ok) {
            throw new Error(`Ошибка загрузки изображения: ${response.statusText}`);
        }

        const blob = await response.blob(); // Получаем Blob из ответа
        return blob; // Возвращаем Blob
    } catch (error) {
        console.error('Ошибка при загрузке изображения:', error);
        return null; // Возвращаем null в случае ошибки
    }
};
