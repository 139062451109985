import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { TFinancePlan } from '../../types/finance';

type TState = {
  list: TFinancePlan[];
  loading: boolean;
  loaded: boolean;
  errorOnLoad: boolean;
};

const initialState: TState = {
  list: [],
  loaded: false,
  errorOnLoad: false,
  loading: false,
};

const getTariffPlansSlice = createSlice({
  name: 'finance/get-tariff-plans',
  initialState: { ...initialState },
  reducers: {
    setTariffPlansListAction(state, action: PayloadAction<TFinancePlan[]>) {
      state.list = action.payload;
      state.loaded = true;
      state.loading = false;
    },
    setTariffPlansListLoaded: (state, action: PayloadAction<boolean>) => {
      state.loaded = action.payload;
    },
    setTariffPlansListLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTariffPlansListError: (state, action: PayloadAction<boolean>) => {
      state.errorOnLoad = action.payload;
    },
    resetTariffPlansListAction(state) {
      state.list = [];
      state.loaded = false;
      state.loading = false;
      state.errorOnLoad = false;
    },
  },
});

export const {
  setTariffPlansListAction,
  setTariffPlansListLoaded,
  setTariffPlansListLoading,
  setTariffPlansListError,
  resetTariffPlansListAction,
} = getTariffPlansSlice.actions;

export const getTariffPlansReducer = getTariffPlansSlice.reducer;
