import styled from '@emotion/styled';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { Menu } from '../components/layout/menu';
import { UploadProgressBar } from '../components/upload-progress';
import { appMainMenu } from '../constants/app-main-menu';
import { LocaleKeys } from '../locale';

const Wrapper = styled('main')`
  position: relative;
  padding-left: 160px;
  padding-top: 20px;
  width: 100vw;

  @media (max-width: 948px) {
    padding-left: 0;
  }
`;

export const BaseLayout: FC = () => {
  const { t } = useTranslation([LocaleKeys.MENU]);

  const appMenu = useMemo(() => appMainMenu(t), [t]);

  return (
    <Wrapper>
      <Menu list={appMenu} />
      <Outlet />

      <UploadProgressBar />
    </Wrapper>
  );
};
