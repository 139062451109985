import 'blueimp-canvas-to-blob/js/canvas-to-blob.min';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Services } from './services';
import './styles/index.scss';
import './locale';

const container = document.getElementById('root');

dayjs.locale('ru');
dayjs.extend(quarterOfYear);

function App() {
  return (
    <StrictMode>
      <Services />
    </StrictMode>
  );
}

if (container) {
  const root = createRoot(container);
  root.render(<App />);
} else {
  throw new Error("Корневой элемент c id='root' не найден");
}
