import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { LocaleKeys } from '../../../locale';
import {
    Container,
    DesignButton,
    Title,
} from './NotificationsTrafficLimit.styled';

export const NotificationsTrafficLimit = () => {
    const { t } = useTranslation([LocaleKeys.VIDEO]);

    const navigate = useNavigate();

    return (
        <Container>
            <Title>{t('notifications.trafficExhausted')}</Title>

            <DesignButton onClick={() => navigate('/finance')}>
                {t('notifications.improveTariff')}
            </DesignButton>
        </Container>
    );
};
