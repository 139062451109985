import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface UploadProgressState {
    progress: number;
    visible: boolean;
}

const initialState: UploadProgressState = {
    progress: 0,
    visible: false,
};

const uploadProgressSlice = createSlice({
    name: 'uploadProgress',
    initialState,
    reducers: {
        setProgress: (state, action: PayloadAction<UploadProgressState>) => {
            state.progress = action.payload.progress;
            state.visible = action.payload.visible;
        },
    },
});

export const { setProgress } = uploadProgressSlice.actions;
export const uploadProgressReducer = uploadProgressSlice.reducer;
